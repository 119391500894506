import React, { useState } from 'react';
import { Flex, Table, TableCell, TableBody, TableHead, TableRow, TextField, Button, Link } from "@aws-amplify/ui-react";
import './lightbox.css';
import { createScanRecord, createQueueEntry } from '../graphql/mutations'
import { API, Storage } from "aws-amplify"; 
import dayJS from "dayjs";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const month = date.getMonth() + 1; // Add 1 because months are zero-indexed
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date as "MM/DD/YYYY"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}




const Lightbox = ({ data, onClose }) => {
  console.log('lightbox data ' + data);
  const dataSrc = JSON.parse(data);
  const diffsTable = dataSrc.comparisonOutput ? dataSrc.comparisonOutput : null;
  const headerData = dataSrc.headerData ? dataSrc.headerData : null;
  console.log(headerData);
  const checkedScanIds = dataSrc.checkedIds ? dataSrc.checkedIds : null;
  //console.log(checkedScanIds);
  

  if (!headerData) return null;

  const handleReceiveInCircle = async () => {
    try {
      let csvContent = "Variant,Qty\n";
      //const timeString = new Date().toISOString();
  
      diffsTable.forEach(item => {
        const qtyToReceive = document.getElementById(item.Variant).value;
        if (qtyToReceive > 0) csvContent += `${item.Variant},${qtyToReceive}\n`;

      });
  
      const csvBlob = new Blob([csvContent], { type: "text/csv" });

      await Storage.put(`receiving-submissions/${headerData.po_id}-${headerData.warehouse}.csv`, csvBlob, {
        contentType: 'text/csv'
      });
  
      console.log('File successfully uploaded!');

      const queueData = {
        partner: headerData.partner,
        file: `receiving-submissions/${headerData.po_id}-${headerData.warehouse}.csv`,
        user: 'robert_sutherland@worldpantry.com',
        uploadType: 'Receiving Submission',
        dc: headerData.warehouse,
        time: dayJS().format('YYYY-MM-DD-HH-mm-ss'),
        status: 'Pending'
      };

      for (const scanId of checkedScanIds) {
        const receivingRecordInput = {
          id: scanId,
          IsCompleted: true,
          CircleId: headerData.po_id
          // Add other fields as necessary
        };
  
        await API.graphql({
          query: createScanRecord,
          variables: { input: receivingRecordInput },
        });

        await API.graphql({
          query: createQueueEntry,
          variables: { input: queueData },
        });


  
        console.log(`Receiving record created for scan ID: ${scanId}`);
      }
      // setLightboxData(null);
      onClose(); // Close the lightbox
    } catch (error) {
      console.error('Error uploading file: ', error);
    }
  };
  
  return (
    <>
    
    <div className="lightbox">
    <div className="close-button" onClick={onClose}>X</div>
      <div className="lightbox-content">

        <Flex direction="column" alignItems="center">
          <Flex direction="row" width="100%" >
        <Table size='small'>
        <TableHead>
            <TableRow >
              <TableCell as="th" className='po_header' width="200px">PO:</TableCell>
              <TableCell as="th" className='po_header content' >{headerData.po_id}</TableCell>
              <TableCell as="th" className='po_header' >Partner:</TableCell>
              <TableCell as="th" className='po_header content' >{headerData.partner}</TableCell>
              <TableCell as="th" className='po_header'>Warehouse:</TableCell>
              <TableCell as="th" className='po_header content'>{headerData.warehouse}</TableCell>
            </TableRow> 
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Vendor:</TableCell>
            <TableCell className='content'>{headerData.vendor}</TableCell>
            <TableCell>Purchaser:</TableCell>
            <TableCell className='content'>{headerData.purchaser}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Date Issued:</TableCell>
            <TableCell className='content'>{formatDate(headerData.date_issued)}</TableCell>
            <TableCell>Date Expected:</TableCell>
            <TableCell className='content'>{formatDate(headerData.date_expected)}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
        </Table>
        <Button onClick={handleReceiveInCircle}>Receive in Circle</Button>
        </Flex> 
        <Table caption="" highlightOnHover={true} size="small" variation="striped" width='100%'>
          <TableHead >
            <TableRow>
              <TableCell as="th">SKU</TableCell>
              <TableCell as="th">Description</TableCell>
              <TableCell as="th"># Scanned</TableCell>
              <TableCell as="th">Qty Open in Circle PO</TableCell>
              <TableCell as="th">Order Qty</TableCell>
              <TableCell as="th">Note</TableCell>
              <TableCell as="th">Difference</TableCell>
              <TableCell as="th">Qty to Receive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diffsTable.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.Variant}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.onCheckedScanLines}</TableCell>
                <TableCell>{item.onSqlRes}</TableCell>
                <TableCell>{item.order_qty}</TableCell>
                <TableCell className='note'>{item.note}</TableCell>
                <TableCell>{item.diffFromSqlRes}</TableCell>
                <TableCell><TextField id={item.Variant} defaultValue={item.onSqlRes === 0 ? 0 : item.onCheckedScanLines} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Flex>
        <Button onClick={onClose}>Close</Button>
      </div>
    </div>
    </>
  );
};

export default Lightbox;