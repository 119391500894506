import { CheckboxField, Table, TableCell, TableBody, Link, TableHead, TableRow, TextField, Button } from "@aws-amplify/ui-react";
import React, { useState, useEffect } from "react";
import ItemScans  from "./itemScans.js";




export default function ScannedData ({scanData, scanLineData, onCheckboxChange}) {

    const [lightboxData, setLightboxData] = useState(null);

    const openLightbox = (data) => {
        setLightboxData(data);
      };
    
      const closeLightbox = () => {
        setLightboxData(null);
      };

      
    function findLinesById(data, searchString) {
        const result = [];
      
        // Iterate through the properties of the JSON object
        for (const username in data) {
          const user = data[username];
      
          // Check if the user has a "Scans" array
          if (user.Scans && Array.isArray(user.Scans)) {
            // Iterate through the "Scans" array for the current user
            for (const scan of user.Scans) {
              // Check if the "Id" property matches the search string
              if (scan.Id === searchString) {
                // If there is a match, add the "Lines" array to the result
                if (scan.Lines) {
                  result.push(...scan.Lines);
                }
              }
            }
          }
        }
        openLightbox(result);
        //alert(JSON.stringify(result));
    }
    
        
        return (
            <>
            <Table caption="" highlightOnHover={true} size="small" variation="striped" width='50%' >
                <TableHead>
                <TableRow>
                    <TableCell as="th">Scan Device</TableCell>
                    <TableCell as="th">Partner</TableCell>    
                    <TableCell as="th">Start Time</TableCell>    
                    <TableCell as="th">End Time</TableCell>    
                    <TableCell as="th"># Barcodes Scanned</TableCell> 
                    <TableCell as="th">Circle ID</TableCell>
                    <TableCell as="th">Select</TableCell> 
                </TableRow>
            </TableHead>
                <TableBody>
                    {scanData.map((scanGroup) => (
                        <TableRow key={scanGroup.Id}>
                        <TableCell>{scanGroup.Username}</TableCell>
                        <TableCell>{scanGroup.Partner}</TableCell>
                        <TableCell>{scanGroup.StartTime}</TableCell>
                        <TableCell>{scanGroup.EndTime}</TableCell>
                        <TableCell><Link onClick={(e) => {findLinesById(scanLineData, scanGroup.Id)}}>{scanGroup.LineCount}</Link> </TableCell>
                        {/* <Link onClick={(e) => {myFunc(scanLineData.filter((record) => record[scanGroup].Scans.Id === scanGroup.Id))}}></Link> */}
                        <TableCell>{scanGroup.CircleId}</TableCell>
                        <TableCell><CheckboxField id={scanGroup.Id} isDisabled={!!scanGroup.CircleId || scanGroup.Partner === 'Unknown'} onChange={(e) => onCheckboxChange(scanGroup.Id, e.target.checked)}></CheckboxField></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                </Table>
                   {lightboxData && (
                    <ItemScans data={lightboxData} onClose={closeLightbox} />
                  )}
        </>
        )
}