import logo from "./logo.svg";
import React, { useState } from "react";
import awsconfig from './aws-exports';
import "./App.css";

import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import {

  Authenticator,
  Image,
  Link,
  View,
  useTheme
} from "@aws-amplify/ui-react";
import AdminContent from './adminContent.js';
import ReceivingContent from './receivingContent.js';
import PurchasingContent from './purchasingContent.js';

Amplify.configure(awsconfig);


export default function App() {
  const [selectedRole, setSelectedRole] = useState(); 
  const authComponents = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image src={logo} className="App-logo" alt="logo" width="300px"/>
          <br />
        </View>
      );
    },
  }


// TODO: Implement SSR with protected routes rather than using client side routing
function displayRoleContent(role, user, signOut) {
  switch (role) {
    case 'admin': return <AdminContent user={user} signOut={signOut}/>;
    case 'purchasing': return <PurchasingContent user={user} signOut={signOut}/>
    case 'receiving': return <ReceivingContent user={user} signOut={signOut}/>
    default: return null;
  }

}


function selectRole(roles) {
  if (roles.length > 1) {
    return <div>&nbsp;&nbsp;Choose role:&nbsp;
      {roles.includes('admin') ? <><Link onClick={() => setSelectedRole('admin')}>Admin</Link> / </> : null} 
      {roles.includes('purchasing') ? <Link onClick={() => setSelectedRole('purchasing')}>Purchasing</Link> : null} 
      {roles.includes('receiving') ? <> / <Link onClick={() => setSelectedRole('receiving')}>Receiving</Link></> : null}
      </div>
  }
  return null;
}

function checkUserRole(role) {
  const roles = role.split(",");
  if (roles.length > 1) return <>{selectRole(roles)}<br /></>
  
}
  
  return (
    
    <Authenticator hideSignUp  components={authComponents} >
    {({ signOut, user }) => (
    <>{checkUserRole(user.attributes.profile)} {displayRoleContent (selectedRole ? selectedRole : user.attributes.profile.split(',')[0], user, signOut)}</>
  )}
  </Authenticator>
  );
}
