import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API, Storage, Auth, Hub } from "aws-amplify";
import {
  Button,
  Heading,
  Icon,
  Image,
  View,
  Link,
  Text,
  Flex,
  useTheme,
} from "@aws-amplify/ui-react";
import {
  listPurchaseOrders,
  purchaseOrdersByDate,
  listQueueEntries,
} from "./graphql/queries";

import PurchaseOrderUpload from "./components/purchaseOrderUpload.js";
import CompletedPOTable from "./components/CompletedPOTable.js";
import JobQueueTable from "./components/JobQueueTable.js";

export default function PurchasingContent({ user, signOut }) {
  const { tokens } = useTheme();
  const [purchaseOrders, setPurchaseOrders] = useState([]); // Completed Purchase Order Table
  const [incompletePurchaseOrders, setIncompletePurchaseOrders] = useState([]); // Anything that is not in Completed Purchase Order Table
  // const [nextToken, setNextToken] = useState(null); //track tokens for API call to get more entries for Completed Purchase Order Table
  // const [loadingState, setLoadingState] = useState(false);  //disable 'get more...' when loading
  const [userEmail, setUserEmail] = useState(); //get the user email from Amplify Auth attributes to pass to components
  const svgPath = `M327.081,0H90.234C74.331,0,61.381,12.959,61.381,28.859v412.863c0,15.924,12.95,28.863,28.853,28.863H380.35 c15.917,0,28.855-12.939,28.855-28.863V89.234L327.081,0z M333.891,43.184l35.996,39.121h-35.996V43.184z M384.972,441.723 c0,2.542-2.081,4.629-4.635,4.629H90.234c-2.55,0-4.619-2.087-4.619-4.629V28.859c0-2.548,2.069-4.613,4.619-4.613h219.411v70.181 c0,6.682,5.443,12.099,12.129,12.099h63.198V441.723z M128.364,128.89H334.15c5.013,0,9.079,4.066,9.079,9.079 c0,5.013-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079C119.285,132.957,123.352,128.89,128.364,128.89z M343.229,198.98c0,5.012-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15 C339.163,189.901,343.229,193.968,343.229,198.98z M343.229,257.993c0,5.013-4.066,9.079-9.079,9.079H128.364 c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15C339.163,248.914,343.229,252.98,343.229,257.993z M343.229,318.011c0,5.013-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15 C339.163,308.932,343.229,312.998,343.229,318.011z`;

  const onUpdate = `subscription onUpdatePurchaseOrder {
    onUpdatePurchaseOrder {
      id
      status
      file
      partner
      dc
      eta
      duedate
      poamount
      numberofitems
      supressupload
      discountpercent
      shipvia
      note
      user
    }
  }`;

  const onUpdateQueue = `subscription onUpdateQueueEntry {
    onUpdateQueueEntry {
      id
      time
      uploadType
      status
      file
      partner
      dc
      user
    }
  }`;

  const onDeleteQueue = `subscription onDeleteQueueEntry {
    onDeleteQueueEntry {
      id
      time
      uploadType
      status
      file
      partner
      dc
      user
    }
  }`;

  const onDelete = `subscription onDeletePurchaseOrder {
    onDeletePurchaseOrder {
      id
      status
      file
      partner
      dc
      eta
      duedate
      poamount
      numberofitems
      supressupload
      discountpercent
      shipvia
      note
      user
    }
  }`;

  const onCreateQueueEntry = `subscription onCreateQueueEntry {
    onCreateQueueEntry {
      id
      uploadType
      time
      file
      status
      user
      partner
      dc
      createdAt
      updatedAt
    }
  }`;

  useEffect(() => {
    getEmail();
    fetchAllPurchaseOrders();
    // fetchNextObjects(null, null);
    getJobQueue();
  }, []);

  useEffect(() => {
    const getCurrentUser = async () => {
      getJobQueue();
    };

    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
        case "cognitoHostedUI":
          getCurrentUser();
          break;
        default:
          break;
      }
    });
    return () => {
      Hub.remove("auth");
    };
  }, []);

  useEffect(() => {
    const updateQueueCreate = API.graphql({
      query: onCreateQueueEntry,
    }).subscribe({
      next: ({ provider, value }) => {
        getJobQueue();
      },
      error: (error) => console.warn(error),
    });

    const updateSub = API.graphql({
      query: onUpdate,
    }).subscribe({
      next: ({ provider, value }) => {
        //fetchNextObjects(null, null)
        fetchAllPurchaseOrders();
        getJobQueue();
      },
      error: (error) => console.warn(error),
    });

    const updateQueueSub = API.graphql({
      query: onUpdateQueue,
    }).subscribe({
      next: ({ provider, value }) => {
        getJobQueue();
      },
      error: (error) => console.warn(error),
    });

    const deleteQueueSub = API.graphql({
      query: onDeleteQueue,
    }).subscribe({
      next: ({ provider, value }) => {
        getJobQueue();
      },
      error: (error) => console.warn(error),
    });

    const deleteSub = API.graphql({
      query: onDelete,
    }).subscribe({
      next: ({ provider, value }) => {
        fetchAllPurchaseOrders();
        //fetchNextObjects(null, null)
        getJobQueue();
      },
      error: (error) => console.warn(error),
    });

    return () => {
      updateSub.unsubscribe();
      deleteSub.unsubscribe();
      updateQueueSub.unsubscribe();
      deleteQueueSub.unsubscribe();
      updateQueueCreate.unsubscribe();
    };
  });

  const fetchAllPurchaseOrders = async () => {
    const allPurchaseOrders = [];

    const fetchItems = async (token) => {
      const apiData = await API.graphql({
        query: listPurchaseOrders,
        variables: {
          filter: { status: { ne: "Pending" } },
          limit: 100,
          nextToken: token,
        },
      });

      const purchaseOrdersFromAPI = apiData.data.listPurchaseOrders.items;

      await Promise.all(
        purchaseOrdersFromAPI.map(async (purchaseOrder) => {
          if (purchaseOrder.file) {
            const url = await Storage.get(purchaseOrder.file);
            purchaseOrder.file = url;
          }
          return purchaseOrder;
        })
      );

      allPurchaseOrders.push(...purchaseOrdersFromAPI);

      if (apiData.data.listPurchaseOrders.nextToken) {
        // If there's a nextToken, recursively fetch more items
        await fetchItems(apiData.data.listPurchaseOrders.nextToken);
      }
    };

    await fetchItems(); // Start fetching with no initial token

    const sortedData = allPurchaseOrders.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB - dateA; // For descending order
    });

    setPurchaseOrders(sortedData);
  };

  // const fetchNextObjects = async (token, existingData) => {
  //   setLoadingState(true)
  //   const apiData = await API.graphql({ query: listPurchaseOrders, variables: {filter: {status: {ne: "Pending"}}, limit: 20, nextToken: token}}); //, variables: { limit: 10}
  //   const purchaseOrdersFromAPI = apiData.data.listPurchaseOrders.items;

  //   await Promise.all(
  //     purchaseOrdersFromAPI.map(async (purchaseOrder) => {
  //       if (purchaseOrder.file) {
  //         const url = await Storage.get(purchaseOrder.file);
  //         purchaseOrder.file = url;
  //       }
  //       return purchaseOrder;
  //     })
  //   );

  //   const sortedData = purchaseOrdersFromAPI.sort((a, b) => {
  //     const dateA = new Date(a.updatedAt);
  //     const dateB = new Date(b.updatedAt);
  //     return dateB - dateA; // For descending order
  //   });
  //   console.log(sortedData)
  //   setPurchaseOrders(existingData ? [...existingData, ...purchaseOrdersFromAPI] : sortedData);
  //   setNextToken(apiData.data.listPurchaseOrders.nextToken);
  //   setLoadingState(false);
  // }

  async function getJobQueue() {
    const apiData = await API.graphql({ query: listQueueEntries });
    const queueEntries = apiData.data.listQueueEntries.items;
    await Promise.all(
      queueEntries.map(async (entry) => {
        if (entry.file) {
          const url = await Storage.get(entry.file);
          entry.file = url;
        }
        return entry;
      })
    );

    setIncompletePurchaseOrders(queueEntries);
  }

  // async function fetchIncompletePurchaseOrders() {
  //   const apiData = await API.graphql({ query: listPurchaseOrders, variables: { filter: {status: {ne: "Completed"}}}});
  //   const purchaseOrdersFromAPI = apiData.data.listPurchaseOrders.items;
  //   await Promise.all(
  //     purchaseOrdersFromAPI.map(async (purchaseOrder) => {
  //       if (purchaseOrder.file) {
  //         const url = await Storage.get(purchaseOrder.file);
  //         purchaseOrder.file = url;
  //       }
  //       return purchaseOrder;
  //     })
  //   );

  //   setIncompletePurchaseOrders(purchaseOrdersFromAPI);
  // }

  async function getEmail() {
    const user = await Auth.currentAuthenticatedUser();
    setUserEmail(user.attributes.email);
  }

  /* 
┌─────────────────────────────────────────────────────────────────────────┐
│                        BEGIN RENDERED PAGE DATA                         │
└─────────────────────────────────────────────────────────────────────────┘
*/
  return (
    <View className="App">
      <Icon
        pathData={svgPath}
        width="20"
        height="20"
        color="var(--amplify-colors-neutral-40)"
        ariaLabel="PO"
      />
      <Flex
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        padding="5px"
      >
        <Image src={logo} className="App-logo" alt="logo" width="300px" />
        <PurchaseOrderUpload email={userEmail} />
        <View hidden={incompletePurchaseOrders.length === 0} width="80%">
          <Heading level={6} fontWeight={tokens.fontWeights.light}>
            Queue
          </Heading>
          <JobQueueTable jobQueue={incompletePurchaseOrders} />
        </View>
        <Heading level={6} fontWeight={tokens.fontWeights.light}>
          Completed POs
        </Heading>
        <CompletedPOTable POData={purchaseOrders} />
        {/* <View textAlign="right" width="80%">
          <View hidden={!nextToken}><Button  isLoading={loadingState} variation="link" size="small" onClick={() => fetchNextObjects(nextToken, purchaseOrders)}>get more...</Button></View>
        </View> */}
        <Flex direction="row" alignItems="center">
          <Text variation="primary" as="p" fontSize="0.7em">
            Logged in as {user.attributes.email}
          </Text>
          <Link size="small" fontSize="0.7em" onClick={signOut}>
            (sign out)
          </Link>
        </Flex>
      </Flex>
    </View>
  );
}
