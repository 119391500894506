import { Table, TableCell, TableBody, TableHead, TableRow, Button, Link } from "@aws-amplify/ui-react";
import { formatName, colorStatus, formatShip, formatTotal, formatDate, formatDateTime, destructButtonText, destructButtonProps } from "../lib/formatFunctions.js";
import { Tooltip } from 'react-tooltip';
import { API, Storage } from "aws-amplify"; //!
import { deleteReceivingRecord} from "../graphql/mutations"; 
import React, { useState, useEffect } from "react";

export default function CompletedReceivingTable({ReceivedTable}) {

    const [receivedPOs, setReceivedPOs] = useState([]); 

    useEffect(() =>{
        
      setReceivedPOs(ReceivedTable);
    }, [ReceivedTable])

    async function deleteJob({ id, poNumber, dc}) {
        const newQueuedJobs = ReceivedTable.filter((job) => job.id !== id);
        setReceivedPOs(newQueuedJobs); 
        await Storage.remove(`receiving-submissions/${poNumber}-${dc}.csv`); //renamed from name
        await API.graphql({
        query: deleteReceivingRecord,
        variables: { input: { id } },
        });
    }


    return (
        <Table caption="" highlightOnHover={true} size="small" variation="striped" width='100%' >
          <TableHead>
            <TableRow>
                <TableCell as="th">User</TableCell>   
                <TableCell as="th">Warehouse</TableCell> 
                {/* <TableCell as="th">Partner</TableCell> */}
                <TableCell as="th">PO #</TableCell>   
                <TableCell as="th">Time</TableCell>
                <TableCell as="th">Status</TableCell>
                <TableCell as="th">Actions</TableCell>
            </TableRow>
        </TableHead>
            <TableBody>
              {receivedPOs.sort(function (a, b) {
                return b.createdAt.localeCompare(a.createdAt);
                }).map((job) => (
                  <TableRow key={job.id }>
                    <TableCell>{job.user}</TableCell>
                    <TableCell>{job.dc}</TableCell>
                    {/* <TableCell>{job.partner}</TableCell> */}
                    <TableCell>{job.poNumber}</TableCell>
                    <TableCell>{job.time}</TableCell>
                    <TableCell>{job.status}</TableCell>
                    <TableCell><Button variation={destructButtonProps(job.status)} onClick={() => deleteJob(job)}> 
                      {destructButtonText(job.status)}
                    </Button></TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
    )
}