import { Table, TableCell, TableBody, TableHead, TableRow, Button, View, Link } from "@aws-amplify/ui-react";
import { formatName, colorStatus, formatShip, formatTotal, formatDate, formatDateTime, destructButtonText, destructButtonProps } from "../lib/formatFunctions.js";
import { Tooltip } from 'react-tooltip';
import { API, Storage } from "aws-amplify"; //!
import { deletePurchaseOrder as deletePurchaseOrderMutation, deleteQueueEntry} from "../graphql/mutations"; 
import React, { useState, useEffect } from "react";

export default function JobQueueTable({jobQueue}) {

    const [queuedJobs, setQueuedJobs] = useState([]); 

    useEffect(() =>{
        
        setQueuedJobs(jobQueue);
    }, [jobQueue])

    async function deleteJob({ id, partner, dc, time, eta, uploadType}) {
        const newQueuedJobs = queuedJobs.filter((job) => job.id !== id);
        
        setQueuedJobs(newQueuedJobs); 
        if (uploadType === 'Purchase Order') {

          await Storage.remove(`po-uploads/${partner.replace(/\s/g,"")}-${dc}_${eta}_${time}.csv`); //renamed from name
          await API.graphql({
          query: deletePurchaseOrderMutation,
          variables: { input: { id } },
          });

        }

        await API.graphql({
          query: deleteQueueEntry,
          variables: { input: { id } },
          });
    }
  


    return (
        <Table caption="" highlightOnHover={true} size="small" variation="striped" width='80%' >
          <TableHead>
            <TableRow>
                <TableCell as="th">Job Type</TableCell>
                <TableCell as="th">User</TableCell>    
                <TableCell as="th">Warehouse</TableCell> 
                <TableCell as="th">Partner</TableCell>
                <TableCell as="th">Time</TableCell>
                <TableCell as="th">Status</TableCell>
                <TableCell as="th">Actions</TableCell>
            </TableRow>
        </TableHead>
            <TableBody>
              {queuedJobs.sort(function (a, b) {
                return b.createdAt.localeCompare(a.createdAt);
                }).map((job) => (
                  <TableRow key={job.id }>
                    <TableCell>{job.uploadType}</TableCell>
                    <TableCell>{job.user}</TableCell>
                    <TableCell>{job.dc}</TableCell>
                    <TableCell>{job.partner}</TableCell>
                    <TableCell>{job.time}</TableCell>
                    <TableCell>{job.status}</TableCell>
                    <TableCell><Button variation={destructButtonProps(job.status)} isDisabled={job.status === 'Processing...'} onClick={() => deleteJob(job)}> 
                    {/* TODO: prevent canceling depending on the user profile  || job.uploadType !== 'Purchase Order' */}
                      {destructButtonText(job.status)}
                    </Button></TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
    )
}