import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API, Storage, Hub } from "aws-amplify"; //!
import findLineResultsByIds from './functions/findScanLinesByIds.js';
import Lightbox from './components/lightbox.js';
//import * as subscriptions from './graphql/subscriptions';
import {
 // withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Link,
  SelectField,
  Table,
  TextField,
  TableCell,
  TableBody,
  Text,
  TableHead,
  TableRow,
  Flex,
  useTheme
} from "@aws-amplify/ui-react";
import { listPurchaseOrders, getRecentScanData, getPurchaseOrderFromDb } from "./graphql/queries";
import {
  updatePurchaseOrder as updatePurchaseOrderMutation,
  deletePurchaseOrder as deletePurchaseOrderMutation,
} from "./graphql/mutations";
import { formatName, colorStatus, formatShip, formatTotal, formatDate, formatDateTime, destructButtonText, destructButtonProps } from "./lib/formatFunctions.js";
import ScannedData from "./components/recentPOScansTable.js";




export default function AdminContent({user, signOut}) {

    const { tokens } = useTheme();
    const [purchaseOrders, setPurchaseOrders] = useState([]); 
    const [purchaseOrdersToken, setPurchaseOrdersToken] = useState('');
    const [linkDisabled, setLinkDisabled] = useState();
    const [scanData, setScanData] = useState(); 
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState();
    const [startDate, setStartDate] = useState();
    const [checkedIds, setCheckedIds] = useState([]); 
    const [scanLineData, setScanLineData] = useState(); 
    const [poNumber, setPoNumber] = useState();
    const [lightboxData, setLightboxData] = useState(null);
  
    //TODO UPDATE BELOW
    const SubscribeToEvents = `subscription onUpdatePurchaseOrder {
      onUpdatePurchaseOrder {
        id
        status
        file
        partner
        dc
        eta
        duedate
        poamount
        numberofitems
        supressupload
        discountpercent
        shipvia
        note
        user
      }
    }`;
  
    useEffect(() => {
      fetchPurchaseOrders();
    }, []);
  
    useEffect(() => {
      const getCurrentUser = async () => {
        //console.log('sign in detected');
        fetchPurchaseOrders();
      };
  
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
          case 'cognitoHostedUI':
            getCurrentUser();
            break;
          default:
            break;
        }
      });
      return () => {
        Hub.remove('auth');
      };
    }, []);
  
    useEffect(() => {
      const subscription = API.graphql({
          query: SubscribeToEvents
      }).subscribe({
        next: ({ provider, value }) => {
          //console.log(provider, value);
          fetchPurchaseOrders();
        },
        error: error => console.warn(error)
    })
  
      return () => {
          subscription.unsubscribe()
      }
  })

  function filterByWarehouse(inputJSON, filterString) {
    const filteredJSON = {
      scans: {},
      summary: []
    };
  
    for (const key in inputJSON.scans) {
      if (key.startsWith(filterString)) {
        filteredJSON.scans[key] = inputJSON.scans[key];
      }
    }
  
    inputJSON.summary.forEach((item) => {
      if (item.Username.startsWith(filterString)) {
        filteredJSON.summary.push(item);
      }
    });
  
    return filteredJSON;
  }

  
    async function fetchPurchaseOrders(token) {
      //console.log('nextToken is ' + token);
      const apiData = await API.graphql({ query: listPurchaseOrders, variables: { limit: 10, nextToken: token}}); //, variables: { limit: 10}
      //console.log(apiData)
      const purchaseOrdersFromAPI = apiData.data.listPurchaseOrders.items;
      //console.log(purchaseOrdersFromAPI)
      await Promise.all(
        purchaseOrdersFromAPI.map(async (purchaseOrder) => {
          if (purchaseOrder.file) {
            const url = await Storage.get(purchaseOrder.file);
            purchaseOrder.file = url;
          }
          return purchaseOrder;
        })
      );
      
      setPurchaseOrders(purchaseOrdersFromAPI);
      setPurchaseOrdersToken(apiData.data.listPurchaseOrders.nextToken);
      setLinkDisabled(apiData.data.listPurchaseOrders.nextToken ? true : false);
    }
  

    async function deletePurchaseOrder({ id, partner, dc, time, eta}) {
      const newPurchaseOrders = purchaseOrders.filter((purchaseOrder) => purchaseOrder.id !== id);
      setPurchaseOrders(newPurchaseOrders);
      await Storage.remove(`${partner.replace(/\s/g,"")}-${dc}_${eta}_${time}.csv`); //renamed from name
      await API.graphql({
        query: deletePurchaseOrderMutation,
        variables: { input: { id } },
      });
    }
  
    async function updatePurchaseOrder(event) {
        event.preventDefault();
        const form = new FormData(event.target);
        const data = {
          id: form.get('id'),
          user: form.get("user"),
          status: form.get("status"),
        };
    
        
        await API.graphql({
          query: updatePurchaseOrderMutation,
          variables: { input: data },
        });
        fetchPurchaseOrders();
        event.target.reset();
      }

    return (
    <View className="App">
   <Flex
     direction="column"
     justifyContent="space-evenly"
     alignItems="center"
     alignContent="center"
     wrap="nowrap"
     gap="1rem"
     padding="5px"
     
   >
   <Image src={logo} className="App-logo" alt="logo" width="300px"/>
 
   <Heading level={3} fontWeight={tokens.fontWeights.light}>Admin Section</Heading>

   
   <View as="form" onSubmit={updatePurchaseOrder}>
   <TextField name="id" label="ID to modify:" type="text" fontWeight={tokens.fontWeights.light} isRequired={true} />
   <TextField name="user" label="set user (use name@worldpantry.com):" type="text" fontWeight={tokens.fontWeights.light} isRequired={true} />
   <TextField name="status" label="set status" type="text" fontWeight={tokens.fontWeights.light} isRequired={true} />
   <Button type="submit" variation="primary"  style={{alignSelf: "flex-end"}} fontWeight={tokens.fontWeights.light} >
        Submit
      </Button>
   </View>

   <Heading level={6} fontWeight={tokens.fontWeights.light}>Current POs</Heading>
   <Table
     caption=""
     highlightOnHover={true}
     size="small"
     variation="striped"
     width='80%'
     >
     <TableHead>
       <TableRow>
         <TableCell as="th">ID</TableCell>
         <TableCell as="th">Vendor</TableCell>
         <TableCell as="th">Warehouse</TableCell>    
         <TableCell as="th">Due Date</TableCell> 
         <TableCell as="th">ETA</TableCell>
         <TableCell as="th">Ship via</TableCell>
         <TableCell as="th">Discount %</TableCell>
         <TableCell as="th">File</TableCell>
         <TableCell as="th">Status</TableCell>
         <TableCell as="th">PO #</TableCell>
         <TableCell as="th">$ Total</TableCell>
         <TableCell as="th"># Items</TableCell>
         <TableCell as="th">Created</TableCell>
         <TableCell as="th">Actions</TableCell>
       </TableRow>
     </TableHead>
     <TableBody>
     {purchaseOrders.sort(function (a, b) {
         return b.createdAt.localeCompare(a.createdAt);
         }).map((purchaseOrder) => (
       <TableRow key={purchaseOrder.id || purchaseOrder.partner}>
        <TableCell>{purchaseOrder.id}</TableCell>
         <TableCell>{purchaseOrder.partner}</TableCell>
         <TableCell>{purchaseOrder.dc}</TableCell>
         <TableCell>{formatDate(purchaseOrder.duedate)}</TableCell>
         <TableCell>{formatDate(purchaseOrder.eta)}</TableCell>
         <TableCell>{formatShip(purchaseOrder.shipvia)}</TableCell>
         <TableCell>{purchaseOrder.discountpercent}</TableCell>
         <TableCell>{purchaseOrder.file && (<Link href={purchaseOrder.file}>[Link]</Link>)}</TableCell>
         <TableCell style={colorStatus(purchaseOrder.status)}>{purchaseOrder.status}</TableCell>
         <TableCell>{purchaseOrder.ponumber}</TableCell>
         <TableCell>{formatTotal(purchaseOrder.poamount)}
         </TableCell>
         <TableCell>{purchaseOrder.numberofitems}</TableCell>
         <TableCell >{formatDateTime(purchaseOrder.createdAt)} by {formatName(purchaseOrder.user)}</TableCell>
         <TableCell><Button variation={destructButtonProps(purchaseOrder.status)} onClick={() => deletePurchaseOrder(purchaseOrder)}>
           {destructButtonText(purchaseOrder.status)}
         </Button></TableCell>
 
       </TableRow>
       ))}
     </TableBody>
   </Table>
   <View textAlign="right" width="80%"><Button variation="link" size="small" onClick={() => fetchPurchaseOrders(purchaseOrdersToken)}>{ !linkDisabled ? 'Back to start' : 'Next Page'}</Button></View>
   <Flex direction="row" alignItems="center"><Text     variation="primary"    as="p"   fontSize="0.7em">Logged in as: {user.attributes.email}</Text>
   <Link size="small" fontSize="0.7em" onClick={signOut}>(sign out)</Link></Flex>
   </Flex>
   
 </View>
 )
} 