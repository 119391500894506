import React, { useState } from 'react';
import { Table, TableCell, TableBody, TableHead, TableRow, TextField, Button, Link } from "@aws-amplify/ui-react";
import './lightbox.css';



const ItemScans = ({ data, onClose }) => {
  return (
    <div className="lightbox">
      <div className="lightbox-content">
        <Table caption="" highlightOnHover={true} size="small" variation="striped" width='100%'>
          <TableHead >
            <TableRow>
              <TableCell as="th">SKU</TableCell>
              <TableCell as="th"># Scanned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.Variant}</TableCell>
                <TableCell>{item.Count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button onClick={onClose}>Close</Button>
      </div>
    </div>
  );
};

export default ItemScans;