/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRecentScanData = /* GraphQL */ `
  query GetRecentScanData($msg: String) {
    getRecentScanData(msg: $msg) {
      statusCode
      body
      data
    }
  }
`;
export const getPurchaseOrderFromDb = /* GraphQL */ `
  query GetPurchaseOrderFromDb($msg: AWSJSON) {
    getPurchaseOrderFromDb(msg: $msg) {
      statusCode
      body
      data
    }
  }
`;
export const getPurchaseOrder = /* GraphQL */ `
  query GetPurchaseOrder($id: ID!) {
    getPurchaseOrder(id: $id) {
      id
      partner
      dc
      user
      time
      eta
      ponumber
      file
      status
      note
      shipvia
      discountpercent
      duedate
      supressupload
      numberofitems
      poamount
      createdAt
      updatedAt
    }
  }
`;
export const listPurchaseOrders = /* GraphQL */ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        partner
        dc
        user
        time
        eta
        ponumber
        file
        status
        note
        shipvia
        discountpercent
        duedate
        supressupload
        numberofitems
        poamount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReceivingRecord = /* GraphQL */ `
  query GetReceivingRecord($id: ID!) {
    getReceivingRecord(id: $id) {
      id
      dc
      user
      time
      poNumber
      file
      diffsFile
      status
      qtySkusScanned
      qtyUnitsScanned
      qtySkusCut
      qtyUnitsCut
      qtySkusOver
      qtyUnitsOver
      qtySkusUnrequested
      qtyUnitsUnrequested
      dollarAmtOfPurchaseOrder
      dollarAmtReceivingDiff
      createdAt
      updatedAt
    }
  }
`;
export const listReceivingRecords = /* GraphQL */ `
  query ListReceivingRecords(
    $filter: ModelReceivingRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceivingRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dc
        user
        time
        poNumber
        file
        diffsFile
        status
        qtySkusScanned
        qtyUnitsScanned
        qtySkusCut
        qtyUnitsCut
        qtySkusOver
        qtyUnitsOver
        qtySkusUnrequested
        qtyUnitsUnrequested
        dollarAmtOfPurchaseOrder
        dollarAmtReceivingDiff
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScanRecord = /* GraphQL */ `
  query GetScanRecord($id: ID!) {
    getScanRecord(id: $id) {
      id
      Username
      StartTime
      Partner
      LineCount
      IsCompleted
      NumberOfUnscannedLines
      Ignore
      CircleId
      createdAt
      updatedAt
    }
  }
`;
export const listScanRecords = /* GraphQL */ `
  query ListScanRecords(
    $filter: ModelScanRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScanRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Username
        StartTime
        Partner
        LineCount
        IsCompleted
        NumberOfUnscannedLines
        Ignore
        CircleId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQueueEntry = /* GraphQL */ `
  query GetQueueEntry($id: ID!) {
    getQueueEntry(id: $id) {
      id
      uploadType
      time
      file
      status
      user
      partner
      dc
      createdAt
      updatedAt
    }
  }
`;
export const listQueueEntries = /* GraphQL */ `
  query ListQueueEntries(
    $filter: ModelQueueEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueueEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uploadType
        time
        file
        status
        user
        partner
        dc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const purchaseOrdersByDate = /* GraphQL */ `
  query PurchaseOrdersByDate(
    $status: String!
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    purchaseOrdersByDate(
      status: $status
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partner
        dc
        user
        time
        eta
        ponumber
        file
        status
        note
        shipvia
        discountpercent
        duedate
        supressupload
        numberofitems
        poamount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
