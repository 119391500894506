import { Table, TableCell, TableBody, TableHead, TableRow, Button, Link } from "@aws-amplify/ui-react";
import { formatName, colorStatus, formatShip, formatTotal, formatDate, formatDateTime, destructButtonText, destructButtonProps } from "../lib/formatFunctions.js";
import { Tooltip } from 'react-tooltip';
import { API, Storage } from "aws-amplify"; 
import { deletePurchaseOrder as deletePurchaseOrderMutation, } from "../graphql/mutations"; 
import React, { useState, useEffect } from "react";

export default function CompletedPOTable ({POData}) {
    useEffect(() =>{
        
        setPurchaseOrders(POData);
    }, [POData])

    const [purchaseOrders, setPurchaseOrders] = useState([]); 
    const [sortColumn, setSortColumn] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('DESC');

    async function deletePurchaseOrder({ id, partner, dc, time, eta}) {
        const newPurchaseOrders = purchaseOrders.filter((purchaseOrder) => purchaseOrder.id !== id);
        setPurchaseOrders(newPurchaseOrders);
        await Storage.remove(`po-uploads/${partner.replace(/\s/g,"")}-${dc}_${eta}_${time}.csv`); 
        await API.graphql({
        query: deletePurchaseOrderMutation,
        variables: { input: { id } },
        });
    }

    
    async function handleSort(column, setCol, order) {
        if (order === 'DESC' && setCol === column) {
          setSortOrder('ASC');
        } else if (order === 'ASC' && setCol === column) {
          setSortOrder('DESC');
        } else {
          setSortColumn(column);
          setSortOrder('DESC');
        }
      }

    function purchaseOrdersTable (data, colSort, colOrder) {
        const dataSorted = [...data].sort((a, b) => {
          return colOrder === 'DESC' ? (b[colSort].toString().localeCompare(a[colSort].toString())) : (a[colSort].toString().localeCompare(b[colSort].toString()));
        })
    
        return (
          <>
          {dataSorted.map((data) => (
          <TableRow key={data.id || data.partner}>
          <TableCell>{data.partner}</TableCell>
          <TableCell>{data.dc}</TableCell>
          <TableCell>{formatDate(data.duedate)}</TableCell>
          <TableCell>{formatDate(data.eta)}</TableCell>
          <TableCell>{formatShip(data.shipvia)}</TableCell>
          <TableCell>{data.discountpercent}</TableCell>
          <TableCell>{data.file && (<Link href={data.file}>[Link]</Link>)}</TableCell>
          <TableCell style={colorStatus(data.status)}>{data.status}</TableCell>
          <TableCell>{data.ponumber}</TableCell>
          <TableCell>{formatTotal(data.poamount)}</TableCell>
          <TableCell>{data.numberofitems}</TableCell>
          <TableCell ><div data-tooltip-id="PO Note" data-tooltip-content={data.note}>{formatDateTime(data.createdAt)} by {formatName(data.user)}</div><Tooltip id="PO Note" /></TableCell>
          <TableCell><Button variation={destructButtonProps(data.status)} isDisabled={data.status === 'Processing...'} onClick={() => deletePurchaseOrder(data)}> {destructButtonText(data.status)} </Button></TableCell>
          </TableRow>
          ))}
          </>
        );
      }


    return (
      <Table caption="" highlightOnHover={true} size="small" variation="striped" width='80%' >
        <TableHead>
        <TableRow>
            <TableCell as="th" onClick={() => handleSort('partner', sortColumn, sortOrder)}>Vendor</TableCell>
            <TableCell as="th" onClick={() => handleSort('dc', sortColumn, sortOrder)}>Warehouse</TableCell>    
            <TableCell as="th">Due Date</TableCell> 
            <TableCell as="th">ETA</TableCell>
            <TableCell as="th">Ship via</TableCell>
            <TableCell as="th">Discount %</TableCell>
            <TableCell as="th" >File</TableCell>
            <TableCell as="th">Status</TableCell>
            <TableCell as="th" onClick={() => handleSort('ponumber', sortColumn, sortOrder)}>PO #</TableCell>
            <TableCell as="th">$ Total</TableCell>
            <TableCell as="th"># Items</TableCell>
            <TableCell as="th" onClick={() => handleSort('createdAt', sortColumn, sortOrder)}>Created</TableCell>
            <TableCell as="th">Actions</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
              {purchaseOrdersTable(purchaseOrders, sortColumn, sortOrder)}
          </TableBody>
          </Table>
    )
}