/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPurchaseOrder = /* GraphQL */ `
  mutation CreatePurchaseOrder(
    $input: CreatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    createPurchaseOrder(input: $input, condition: $condition) {
      id
      partner
      dc
      user
      time
      eta
      ponumber
      file
      status
      note
      shipvia
      discountpercent
      duedate
      supressupload
      numberofitems
      poamount
      createdAt
      updatedAt
    }
  }
`;
export const updatePurchaseOrder = /* GraphQL */ `
  mutation UpdatePurchaseOrder(
    $input: UpdatePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    updatePurchaseOrder(input: $input, condition: $condition) {
      id
      partner
      dc
      user
      time
      eta
      ponumber
      file
      status
      note
      shipvia
      discountpercent
      duedate
      supressupload
      numberofitems
      poamount
      createdAt
      updatedAt
    }
  }
`;
export const deletePurchaseOrder = /* GraphQL */ `
  mutation DeletePurchaseOrder(
    $input: DeletePurchaseOrderInput!
    $condition: ModelPurchaseOrderConditionInput
  ) {
    deletePurchaseOrder(input: $input, condition: $condition) {
      id
      partner
      dc
      user
      time
      eta
      ponumber
      file
      status
      note
      shipvia
      discountpercent
      duedate
      supressupload
      numberofitems
      poamount
      createdAt
      updatedAt
    }
  }
`;
export const createReceivingRecord = /* GraphQL */ `
  mutation CreateReceivingRecord(
    $input: CreateReceivingRecordInput!
    $condition: ModelReceivingRecordConditionInput
  ) {
    createReceivingRecord(input: $input, condition: $condition) {
      id
      dc
      user
      time
      poNumber
      file
      diffsFile
      status
      qtySkusScanned
      qtyUnitsScanned
      qtySkusCut
      qtyUnitsCut
      qtySkusOver
      qtyUnitsOver
      qtySkusUnrequested
      qtyUnitsUnrequested
      dollarAmtOfPurchaseOrder
      dollarAmtReceivingDiff
      createdAt
      updatedAt
    }
  }
`;
export const updateReceivingRecord = /* GraphQL */ `
  mutation UpdateReceivingRecord(
    $input: UpdateReceivingRecordInput!
    $condition: ModelReceivingRecordConditionInput
  ) {
    updateReceivingRecord(input: $input, condition: $condition) {
      id
      dc
      user
      time
      poNumber
      file
      diffsFile
      status
      qtySkusScanned
      qtyUnitsScanned
      qtySkusCut
      qtyUnitsCut
      qtySkusOver
      qtyUnitsOver
      qtySkusUnrequested
      qtyUnitsUnrequested
      dollarAmtOfPurchaseOrder
      dollarAmtReceivingDiff
      createdAt
      updatedAt
    }
  }
`;
export const deleteReceivingRecord = /* GraphQL */ `
  mutation DeleteReceivingRecord(
    $input: DeleteReceivingRecordInput!
    $condition: ModelReceivingRecordConditionInput
  ) {
    deleteReceivingRecord(input: $input, condition: $condition) {
      id
      dc
      user
      time
      poNumber
      file
      diffsFile
      status
      qtySkusScanned
      qtyUnitsScanned
      qtySkusCut
      qtyUnitsCut
      qtySkusOver
      qtyUnitsOver
      qtySkusUnrequested
      qtyUnitsUnrequested
      dollarAmtOfPurchaseOrder
      dollarAmtReceivingDiff
      createdAt
      updatedAt
    }
  }
`;
export const createScanRecord = /* GraphQL */ `
  mutation CreateScanRecord(
    $input: CreateScanRecordInput!
    $condition: ModelScanRecordConditionInput
  ) {
    createScanRecord(input: $input, condition: $condition) {
      id
      Username
      StartTime
      Partner
      LineCount
      IsCompleted
      NumberOfUnscannedLines
      Ignore
      CircleId
      createdAt
      updatedAt
    }
  }
`;
export const updateScanRecord = /* GraphQL */ `
  mutation UpdateScanRecord(
    $input: UpdateScanRecordInput!
    $condition: ModelScanRecordConditionInput
  ) {
    updateScanRecord(input: $input, condition: $condition) {
      id
      Username
      StartTime
      Partner
      LineCount
      IsCompleted
      NumberOfUnscannedLines
      Ignore
      CircleId
      createdAt
      updatedAt
    }
  }
`;
export const deleteScanRecord = /* GraphQL */ `
  mutation DeleteScanRecord(
    $input: DeleteScanRecordInput!
    $condition: ModelScanRecordConditionInput
  ) {
    deleteScanRecord(input: $input, condition: $condition) {
      id
      Username
      StartTime
      Partner
      LineCount
      IsCompleted
      NumberOfUnscannedLines
      Ignore
      CircleId
      createdAt
      updatedAt
    }
  }
`;
export const createQueueEntry = /* GraphQL */ `
  mutation CreateQueueEntry(
    $input: CreateQueueEntryInput!
    $condition: ModelQueueEntryConditionInput
  ) {
    createQueueEntry(input: $input, condition: $condition) {
      id
      uploadType
      time
      file
      status
      user
      partner
      dc
      createdAt
      updatedAt
    }
  }
`;
export const updateQueueEntry = /* GraphQL */ `
  mutation UpdateQueueEntry(
    $input: UpdateQueueEntryInput!
    $condition: ModelQueueEntryConditionInput
  ) {
    updateQueueEntry(input: $input, condition: $condition) {
      id
      uploadType
      time
      file
      status
      user
      partner
      dc
      createdAt
      updatedAt
    }
  }
`;
export const deleteQueueEntry = /* GraphQL */ `
  mutation DeleteQueueEntry(
    $input: DeleteQueueEntryInput!
    $condition: ModelQueueEntryConditionInput
  ) {
    deleteQueueEntry(input: $input, condition: $condition) {
      id
      uploadType
      time
      file
      status
      user
      partner
      dc
      createdAt
      updatedAt
    }
  }
`;
