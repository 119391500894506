import dayjs from "dayjs";

export function formatName (nameObj) {
    let name = nameObj.replace(/(?:@.*)/, '').replace(/_/g, ' ').split(" ");
    for(let i = 0; i< name.length; i++){
      name[i] = name[i][0].toUpperCase() + name[i].slice(1);
    }
    
    return name.join(" ");
  }

export function colorStatus (status) {
  switch (status) {
    case 'Pending':
      return {color: "var(--amplify-colors-teal-80)", backgroundColor: "var(--amplify-colors-teal-20)"};
    case 'Completed':
      return {color: "var(--amplify-colors-green-80)", backgroundColor: "var(--amplify-colors-green-20)"};
    case 'Processing...':
      return {color: "var(--amplify-colors-yellow-80)", backgroundColor: "var(--amplify-colors-yellow-20)"};
    case 'Error':
      return {color: "var(--amplify-colors-red-80)", backgroundColor: "var(--amplify-colors-red-20)"};
    default:
      return {color: "var(--amplify-colors-teal-80)", backgroundColor: "var(--amplify-colors-teal-20)"};
  }
}

export function formatShip(status) {
  switch (status) {
  case 'standard':
    return 'Delivered';
  case 'wppickup':
    return 'WP Pick-up';
  case 'partnerbillto':
    return 'Arrange & Bill WP';
  default:
    return '';
  }
}


export function formatTotal (amount) {
  if (amount) return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return ''
}

export function formatDate (dateObj) {
  if (!dateObj) return '';
  return dayjs(dateObj).format('M/D/YYYY')
}

export function formatDateTime (dateObj) {
  if (!dateObj) return '';
  return dayjs(dateObj).format('M/D/YYYY h:mm A')
}

export function destructButtonText (status) {
  switch (status) {
    case 'Pending':
      return 'Cancel';
    case 'Processing...':
      return 'Processing...';
    default:
      return 'Clear';
  }
}

export function destructButtonProps (status) {
  switch (status) {
    case 'Pending':
      return "destructive";
    case 'Processing...':
      return "primary" ;
    default:
      return "warning";
  }
}
