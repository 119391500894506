export default async function findLineResultsByIds(data, searchIds) {
    const results = [];
    console.log('data in findscanlines' + JSON.stringify(data));
    try {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const scans = data[key].Scans;
              for (const scan of scans) {
                if (searchIds.includes(scan.Id)) {
                  results.push(...scan.Lines);
                }
              }
            }
          }
          
        console.log('results are ' + JSON.stringify(results));
          return await aggregateLineResults(results);
    } catch (error) {
        console.log('error in findLineResults', error);
        return 'error';
    }
    
}


async function aggregateLineResults(data) {
    const aggregatedResults = {};
    try {
        for (const line of data) {
            const variant = line.Variant;
            const count = line.Count;
        
            if (aggregatedResults[variant]) {
              aggregatedResults[variant].Count += count;
            } else {
              aggregatedResults[variant] = { Variant: variant, Count: count };
            }
          }
          console.log('aggregated results ' + JSON.stringify(aggregatedResults));
          return Object.values(aggregatedResults);
    } catch (error) {
        console.log('error in aggregate line results', error);
    }
   
  }