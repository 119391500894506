/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://zhodra7p5nhn3neckp6t3l3qqm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-helt33p6nbgdfgpnoonfg3jt7e",
    "aws_cognito_identity_pool_id": "us-east-1:e68b8c24-62ff-43d8-b434-20d67ff94b7c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_v3vWtbk36",
    "aws_user_pools_web_client_id": "16knmbv9q43hav90bgbucv2jcu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pouploadapp8cc12694b8244eaebb279dda29bec808234948-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
