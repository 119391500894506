import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API, Storage, Hub } from "aws-amplify"; //!
import findLineResultsByIds from './functions/findScanLinesByIds.js';
import Lightbox from './components/lightbox.js';
//import * as subscriptions from './graphql/subscriptions';
import {
 // withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Link,
  SelectField,
  Table,
  TextField,
  TableCell,
  TableBody,
  Text,
  TableHead,
  TableRow,
  Flex,
  useTheme
} from "@aws-amplify/ui-react";
import { getRecentScanData, getPurchaseOrderFromDb, listQueueEntries, listReceivingRecords } from "./graphql/queries";
// import {
//   updatePurchaseOrder as updatePurchaseOrderMutation,
//   deletePurchaseOrder as deletePurchaseOrderMutation,
// } from "./graphql/mutations";
// import { formatName, colorStatus, formatShip, formatTotal, formatDate, formatDateTime, destructButtonText, destructButtonProps } from "./lib/formatFunctions.js";
import ScannedData from "./components/recentPOScansTable.js";
import JobQueueTable from './components/JobQueueTable.js';
import CompletedReceivingTable from './components/CompletedReceivingTable.js';
//import { onUpdateReceivingRecord } from "./graphql/subscriptions";
import * as subscriptions from './graphql/subscriptions';

import { CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';

Hub.listen('api', (data) => {
  const { payload } = data;
  if (payload.event === CONNECTION_STATE_CHANGE) {
    const connectionState = payload.data.connectionState;
    //console.log(data)
    console.log(connectionState);
  }
});

export default function AdminContent({user, signOut}) {

    const { tokens } = useTheme();
    // const [linkDisabled, setLinkDisabled] = useState();
    const [scanData, setScanData] = useState(); 
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedWarehouse, setSelectedWarehouse] = useState();
    const [startDate, setStartDate] = useState();
    const [checkedIds, setCheckedIds] = useState([]); 
    const [scanLineData, setScanLineData] = useState(); 
    const [poNumber, setPoNumber] = useState();
    const [lightboxData, setLightboxData] = useState(null);
    const [jobQueue, setJobQueue] = useState([]);
    const [receivingTable, setReceivingTable] = useState([]);
  
    //TODO UPDATE BELOW
    const onCreateReceivingRecord = `subscription OnCreateReceivingRecord {
      onCreateReceivingRecord {
        id
        dc
        user
        time
        poNumber
        file
        diffsFile
        status
        createdAt
        updatedAt
      }
    }`;

    const onUpdateReceivingRecord = `subscription OnUpdateReceivingRecord {
      onUpdateReceivingRecord {
        id
        dc
        user
        time
        poNumber
        file
        diffsFile
        status
        qtySkusScanned
        qtyUnitsScanned
        qtySkusCut
        qtyUnitsCut
        qtySkusOver
        qtyUnitsOver
        qtySkusUnrequested
        qtyUnitsUnrequested
        dollarAmtOfPurchaseOrder
        dollarAmtReceivingDiff
        createdAt
        updatedAt
      }
    }`;

    const onCreateQueueEntry = `subscription onCreateQueueEntry {
      onCreateQueueEntry {
        id
        uploadType
        time
        file
        status
        user
        partner
        dc
        createdAt
        updatedAt
      }
    }`;
    const onDeleteQueue = `subscription onDeleteQueueEntry {
      onDeleteQueueEntry {
        id
        time
        uploadType
        status
        file
        partner
        dc
        user
      }
    }`;

    const onUpdateQueue = `subscription onUpdateQueueEntry {
      onUpdateQueueEntry {
        id
        time
        uploadType
        status
        file
        partner
        dc
        user
      }
    }`;  
  
    useEffect(() => {
      // fetchPurchaseOrders();
      getJobQueue();
      getReceivingTable()
    }, []);
  
    useEffect(() => {
      const getCurrentUser = async () => {
        //console.log('sign in detected');
        // fetchPurchaseOrders();
      };
  
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
          case 'cognitoHostedUI':
            getCurrentUser();
            break;
          default:
            break;
        }
      });
      return () => {
        Hub.remove('auth');
      };
    }, []);
  

    useEffect(() => {
    const createQueueRecord = API.graphql({
      query: onCreateQueueEntry
      }).subscribe({
        next: ({ provider, value }) => {
          getJobQueue();
        },
        error: error => console.warn(error)
      })

      return () => createQueueRecord.unsubscribe();
    }, [onCreateQueueEntry]);

    useEffect(() => {
    const updateReceivingRecord = API.graphql({
      query: onUpdateReceivingRecord
      }).subscribe({
        next: ({ provider, value }) => {
          getReceivingTable();
          console.log('receving record update event fired')
        },
        error: error => console.warn(error)
      })

    
    return () => updateReceivingRecord.unsubscribe();
    }, [onUpdateReceivingRecord]);

    useEffect(() => {
    const createReceivingRecord = API.graphql(
          { query: onCreateReceivingRecord }
        ).subscribe({
          next: ({ provider, value }) => {
            getReceivingTable();
            console.log('receving record create event fired');
            console.log({ provider, value })},
          error: (error) => console.warn(error)
        });
      
    return () => createReceivingRecord.unsubscribe();
    }, [onCreateReceivingRecord]);

  useEffect(() => {
    const updateQueueRecord = API.graphql({
      query: onUpdateQueue
    }).subscribe({
      next: ({ provider, value }) => {
        console.log('deleteQueueRecord fired')
        getJobQueue();
      },
      error: error => console.warn(error)
    });

  return () => updateQueueRecord.unsubscribe();
  }, [onUpdateQueue]);

  useEffect(() => {
  const deleteQueueRecord = API.graphql({
    query: onDeleteQueue
    }).subscribe({
      next: ({ provider, value }) => {
        console.log('deleteQueueRecord fired')
        getJobQueue();
        //getReceivingTable();
      },
      error: error => console.warn(error)
    });
  return () => deleteQueueRecord.unsubscribe();
  }, [onDeleteQueue]);
  




  async function getJobQueue() {
    console.log('getting the job queue')
    const apiData = await API.graphql({ query: listQueueEntries }); 
    const queueEntries = apiData.data.listQueueEntries.items;
    await Promise.all(
      queueEntries.map(async (entry) => {
        if (entry.file) {
          const url = await Storage.get(entry.file);
          entry.file = url;
        }
        return entry;
      })
    );
      
    setJobQueue(queueEntries);
    console.log('job queue updated')
  }

  async function getReceivingTable() {

    try {
      console.log('getting Receiving Table')
      const apiData = await API.graphql({ query: listReceivingRecords }); 
      const receivingRecords = apiData.data.listReceivingRecords.items;
      await Promise.all(
        receivingRecords.map(async (entry) => {
          if (entry.file) {
            const url = await Storage.get(entry.file);
            entry.file = url;
          }
          return entry;
        })
      );
        
      setReceivingTable(receivingRecords);
      console.log('receiving table updated')
    } catch (error) {
      console.log('error getting receiving table ' + error.message)
    }
 
  }

  function filterByWarehouse(inputJSON, filterString) {
    const filteredJSON = {
      scans: {},
      summary: []
    };
  
    for (const key in inputJSON.scans) {
      if (key.startsWith(filterString)) {
        filteredJSON.scans[key] = inputJSON.scans[key];
      }
    }
  
    inputJSON.summary.forEach((item) => {
      if (item.Username.startsWith(filterString)) {
        filteredJSON.summary.push(item);
      }
    });
  
    return filteredJSON;
  }

  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      // If the checkbox is checked, add the ID to the checkedIds array
      setCheckedIds((prevCheckedIds) => [...prevCheckedIds, id]);
    } else {
      // If the checkbox is unchecked, remove the ID from the checkedIds array
      setCheckedIds((prevCheckedIds) =>
        prevCheckedIds.filter((checkedId) => checkedId !== id)
      );
    }
  };


  async function getScanData(startDate) {
    if (!selectedWarehouse) { alert('Please select a warehouse'); return };
    setScanData(null);
    setButtonLoading(true);
    const apiData = await API.graphql({ query: getRecentScanData, variables: {msg: startDate} }); 
    //console.log(apiData);
    const data = JSON.parse(apiData.data.getRecentScanData.data);
    //console.log(data);
    const filteredData = filterByWarehouse(data, selectedWarehouse);
    setScanData(filteredData.summary);
    setScanLineData(filteredData.scans);
    console.log(filteredData.scans);
    setButtonLoading(false);
  }

  async function handleAssignPO() {
    const poPattern = /^00\d{5}$/;
    const poPattern2 = /^\d{5}$/;
    const pass = poPattern.test(poNumber) ? poNumber : poPattern2.test(poNumber) ? '00' + poNumber : null;
    setPoNumber(pass);
    if (!pass) { alert('PO number not valid'); return }

    const arg = await printCheckedObjects();
    if (arg === null) {alert('Checked lines is null'); return }

    // console.log('printchecked in handleAssignPO ' + JSON.stringify(arg));
    const apiData = await API.graphql({ query: getPurchaseOrderFromDb, variables: {msg: JSON.stringify(arg)}}); 
    // console.log('apiData: ' + apiData.data.getPurchaseOrderFromDb.data);
    const diffsTable = apiData.data.getPurchaseOrderFromDb.data
    // console.log('diffs table ' + diffsTable);
    //console.log(JSON.parse(diffsTable) ? 'is array' : 'is not array');

    openLightbox(diffsTable);
  }


  const openLightbox = (data) => {
    setLightboxData(data);
  };

  const closeLightbox = () => {
    setLightboxData(null);
    setCheckedIds(null);
    setScanData(null);
    getJobQueue();
    //getScanData(startDate);

  };

  async function printCheckedObjects() {
    const checkedObjects = scanData.filter((scanGroup) =>
      checkedIds.includes(scanGroup.Id)
    );
    // console.log('Checked Objects:', checkedObjects.map(obj => obj.Id));
    const checkedScanLines = await findLineResultsByIds(scanLineData, checkedObjects.map(obj => obj.Id));
    if (checkedScanLines === 'error') return null;
    // console.log({poNumber, checkedIds: checkedObjects.map(obj => obj.Id), checkedScanLines})
    return {poNumber, checkedIds: checkedObjects.map(obj => obj.Id), checkedScanLines}
  };
  

    return (
    <View className="App">
   <Flex
     direction="column"
     justifyContent="space-evenly"
     alignItems="center"
     alignContent="center"
     wrap="nowrap"
     gap="1rem"
     padding="5px"
     
   >
   <Image src={logo} className="App-logo" alt="logo" width="300px"/>
 
   <Heading level={3} fontWeight={tokens.fontWeights.light}>Receive from Barcode Scans</Heading>

   {!!scanData ? <>   <Flex direction="row"><TextField id='PONumber' placeholder="PO Number" onChange={(e) => {setPoNumber(e.target.value)}}/>
   <Button size="small" onClick={handleAssignPO}>Assign checked rows to Circle PO</Button></Flex>
   <ScannedData scanData={scanData} scanLineData={scanLineData} onCheckboxChange={handleCheckboxChange} /></> :  <Flex direction="row">
   <SelectField label="Warehouse" value={selectedWarehouse} onChange={(e) => setSelectedWarehouse(e.target.value)} placeholder="Select a warehouse...">
   <option value="CDC">CDC</option>
   <option value="MDC">MDC</option>
   <option value="PDC">PDC</option>
   </SelectField>
    <TextField type="date" name="start" label="Start Date" onChange={(e) => setStartDate(e.target.value)}></TextField> 
   <Button isLoading={buttonLoading} onClick={() => getScanData(startDate)}>Get Recent Scans</Button></Flex>}
   {lightboxData && (
    <Lightbox data={lightboxData} onClose={closeLightbox} />
  )}
  <View alignContent='bottom'>
  <Button onClick={(e) => setScanData(null)} variation="link" size="small">Back to start</Button>
  {jobQueue.length > 0 ? <JobQueueTable jobQueue={jobQueue} /> : null}
   {receivingTable.length > 0 ? <CompletedReceivingTable ReceivedTable={receivingTable} /> : null}
   <Flex direction="row" alignItems="center"><Text     variation="primary"    as="p"   fontSize="0.7em">Logged in as: {user.attributes.email}</Text>
   <Link size="small" fontSize="0.7em" onClick={signOut}>(sign out)</Link></Flex>
   </View>
   </Flex>
   
 </View>
 )
} 

